<template>
    <Layout>
        <div
            class="d-flex align-items-center justify-content-between mb-2 ">
            <coupon-tabs />
            <button
                :class="`btn btn-primary ${loading ? 'disabled' : ''}`"
                @click="create_modal = true">
                {{ $t('coupon payment') }}
            </button>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card data">
                    <div class="card-header bg-light bg-soft filters">
                        <b-form inline>
                            <table
                                class="table table-bordered table-nowrap mb-0">
                                <tbody>
                                    <tr>
                                        <td class="text-center">
                                            {{ $t('search') }}
                                        </td>
                                        <td lass="bg-white">
                                            <div class="d-flex gap-1 w-100">
                                                <datepicker
                                                    v-model="filters.from"
                                                    :first-day-of-week="0"
                                                    lang="en"
                                                    confirm
                                                    class="form-control bg-white"></datepicker>

                                                <label class="input-group-text"
                                                    >~</label
                                                >
                                                <datepicker
                                                    v-model="filters.to"
                                                    :first-day-of-week="0"
                                                    lang="en"
                                                    confirm
                                                    class="form-control bg-white"></datepicker>
                                                <button
                                                    class="btn btn-outline-info flex-grow-1"
                                                    @click="setDay('today')">
                                                    {{ $t('today') }}
                                                </button>
                                                <button
                                                    class="btn btn-outline-success flex-grow-1"
                                                    @click="
                                                        setDay('yesterday')
                                                    ">
                                                    {{ $t('yesterday') }}
                                                </button>
                                            </div>
                                        </td>
                                        <td class="bg-white" style="width: 15%">
                                            <multiselect
                                                :placeholder="`${$t(
                                                    'select status'
                                                )}`"
                                                v-model="filters.status"
                                                :options="status_options">
                                                <template
                                                    v-slot:singlelabel="{
                                                        value,
                                                    }">
                                                    <span
                                                        class="flex-fill ps-2"
                                                        >{{
                                                            $t(value.value)
                                                        }}</span
                                                    >
                                                </template>
                                                <template
                                                    v-slot:option="{ option }">
                                                    <span>{{
                                                        $t(option.value)
                                                    }}</span>
                                                </template>
                                            </multiselect>
                                        </td>
                                        <td class="text-center">
                                            {{ $t('search') }}
                                        </td>
                                        <td class="bg-white">
                                            <input
                                                type="text"
                                                class="form-control"
                                                :placeholder="
                                                    $t(`enter keyword`)
                                                "
                                                v-model="filters.search" />
                                        </td>
                                        <td>
                                            <button
                                                :class="`btn btn-danger ${
                                                    loading ? 'disabled' : ''
                                                }`"
                                                @click="resetFilter()">
                                                {{ $t('reset') }}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-form>
                    </div>
                    <div class="card-body">
                        <div ref="tableScroller" class="table-responsive">
                            <LoadingTable
                                :cols="6"
                                :rows="50"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">
                                            {{ $t('username') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('amount') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('code') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('note') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('created at') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('created by') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('claimed at') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('expiration date') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('management') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(r, i) in data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            {{
                                                i +
                                                1 +
                                                (data.current_page - 1) *
                                                    data.per_page
                                            }}
                                        </td>
                                        <td class="text-start">
                                            <div class="td-username">
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            $route.params.role
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>
                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            $route.params.role
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><i
                                                        :class="`mdi mdi-numeric-${
                                                            r.user[
                                                                $route.params
                                                                    .role
                                                            ].level
                                                        }-box level-${
                                                            r.user[
                                                                $route.params
                                                                    .role
                                                            ].level
                                                        } fs-5`"></i>
                                                    {{ r.user.username }}</a
                                                >
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            {{
                                                numberString(parseInt(r.amount))
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.code }}
                                        </td>
                                        <td class="text-start">
                                            {{ r.note }}
                                        </td>
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-info':
                                                        r.status == 'unclaimed',
                                                    'text-success fw-bold':
                                                        r.status == 'claimed',
                                                    'text-danger':
                                                        r.status == 'expired',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.created_by.username }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                r.claimed_at
                                                    ? dateYmD(
                                                          r.claimed_at,
                                                          true
                                                      )
                                                    : ''
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.expired_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            <div class="button-items">
                                                <button
                                                    v-if="
                                                        [
                                                            'unclaimed',
                                                            'expired',
                                                        ].includes(r.status)
                                                    "
                                                    class="btn btn-outline-danger btn-sm"
                                                    @click="remove(r)">
                                                    {{ $t('delete') }}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                v-if="!data.list.length && !loading">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <Pagination :data="data" @emitPage="initList" />
                    </div>
                </div>
            </div>
            <b-modal
                v-model="create_modal"
                centered
                hide-footer
                :title="`${$t(`coupon`)}`"
                @show="focusUsername"
                @hidden="reset">
                <b-form>
                    <b-form-group
                        class="mb-3"
                        :label="$t('username')"
                        label-cols-sm="3">
                        <input
                            ref="username"
                            :readonly="Boolean($route.query.username)"
                            type="text"
                            class="form-control"
                            :class="{
                                'is-invalid':
                                    pl.username && v$.pl.username.$error,
                                'is-valid':
                                    pl.username && !v$.pl.username.$error,
                            }"
                            :placeholder="`${$t('enter username')}`"
                            v-model="pl.username"
                            list="usernames" />
                        <div
                            v-if="
                                pl.username &&
                                v$.pl.username.validUsername.$invalid
                            "
                            class="invalid-feedback">
                            {{ $t(v$.pl.username.validUsername.$message) }}
                        </div>
                        <div
                            v-if="v$.pl.username.required.$invalid"
                            class="invalid-feedback">
                            {{ v$.pl.username.required.$message }}
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="mb-3"
                        :label="$t('amount')"
                        label-cols-sm="3">
                        <VueNumberFormat
                            :disabled="v$.pl.username.$invalid"
                            class="form-control rounded-end"
                            :class="{
                                'is-invalid': submitted && v$.pl.amount.$error,
                            }"
                            v-model:value="pl.amount"
                            :options="{
                                precision: 0,
                                prefix: '',
                                suffix: '',
                                thousand: ',',
                                acceptNegative: false,
                                isInteger: true,
                            }"></VueNumberFormat>
                        <div
                            v-for="(item, index) in v$.pl.amount.$errors"
                            :key="index"
                            class="invalid-feedback">
                            <span v-if="item.$message">
                                {{ $t(item.$message) }}
                            </span>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="mb-3"
                        :label="$t('code')"
                        label-cols-sm="3">
                        <div class="d-flex align-items-start gap-1">
                            <div class="flex-fill">
                                <input
                                    readonly
                                    :value="pl.code"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.code.$error,
                                        'bg-white': !v$.pl.username.$invalid,
                                    }" />
                                <div
                                    v-for="(item, index) in v$.pl.code.$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">{{
                                        $t(item.$message)
                                    }}</span>
                                </div>
                            </div>
                            <button
                                :disabled="v$.pl.username.$invalid"
                                type="button"
                                class="btn"
                                :class="{
                                    'btn-info': !v$.pl.username.$invalid,
                                    'btn-light': v$.pl.username.$invalid,
                                }"
                                @click="generateCode()">
                                {{ $t('generate') }}
                            </button>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="mb-3"
                        :label="$t('expiration date')"
                        label-cols-sm="3">
                        <datepicker
                            :disabled="v$.pl.username.$invalid"
                            v-model="pl.expired_at"
                            :first-day-of-week="0"
                            lang="en"
                            :lowerLimit="addDaysToDate(1)"
                            :class="`form-control ${
                                v$.pl.username.$invalid ? '' : 'bg-white'
                            }`"></datepicker>
                    </b-form-group>
                    <b-form-group
                        class="mb-3"
                        :label="$t('note')"
                        label-cols-sm="3">
                        <textarea
                            :disabled="v$.pl.username.$invalid"
                            v-model="pl.note"
                            class="form-control"
                            style="resize: none"
                            rows="5"
                            :class="{
                                'is-invalid': submitted && v$.pl.note.$error,
                            }"></textarea>
                        <div
                            v-for="(item, index) in v$.pl.note.$errors"
                            :key="index"
                            class="invalid-feedback">
                            <span v-if="item.$message">{{
                                $t(item.$message)
                            }}</span>
                        </div>
                    </b-form-group>

                    <button
                        class="btn btn-warning float-end"
                        @click.prevent="submitForm()"
                        :disabled="loading">
                        {{ $t('issue') }}
                    </button>
                </b-form>
            </b-modal>
        </div>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import convert from '../../../mixins/convert';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
import string from '../../../mixins/string';
import random from '../../../mixins/random';
import { required, minValue, maxValue, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import MoneyManagementTabs from '@/components/money-management-tabs';
import AgentManagementTabs from '@/components/agent-management-tabs';
import CouponTabs from '@/components/coupon-tabs.vue';
const validIP = (value) => {
    const regex = /\b(?:\d{1,3}\.){3}\d{1,3}\b/;
    return helpers.req(value) && regex.test(value);
};
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        MoneyManagementTabs,
        AgentManagementTabs,
        CouponTabs,
    },
    mixins: [datetime, number, convert, ui, string, random],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                keyword: null,
                status: null,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
            },
            status_options: [
                { name: this.$t('unclaimed'), value: 'unclaimed' },
                { name: this.$t('claimed'), value: 'claimed' },
                { name: this.$t('expired'), value: 'expired' },
            ],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            create_modal: false,
            loading: false,
            hoverDivLeft: 0,
            hoverDivTop: 0,
            user_options: [],
            pl: {
                _id: null,
                user_id: null,
                username: null,
                amount: null,
                code: null,
                expired_at: this.addDaysToDate(1),
                note: null,
            },
            pl_origin: {},
            submitted: false,
        };
    },
    validations: {
        pl: {
            username: {
                required: helpers.withMessage('required', required),
                validUsername: helpers.withMessage(
                    'must be a valid username',
                    function (value) {
                        return this.pl.user_id != null;
                    }
                ),
            },
            amount: {
                required: helpers.withMessage('required', required),
                minValueValue: helpers.withMessage('required', minValue(1)),
                maxValueValue: helpers.withMessage(
                    'the maximum value is 9,999,999,999',
                    maxValue(9999999999)
                ),
            },
            code: {
                required: helpers.withMessage('required', required),
            },
            note: {
                required: helpers.withMessage('required', required),
            },
        },
    },
    watch: {
        filters: {
            handler() {
                this.initList(1);
            },
            deep: true,
        },
        'pl.username'() {
            this.getUsernames();
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    computed: {
        viewMode() {
            return this.pl._id != null;
        },
        forms() {
            var m_fd = this.formDataToObject(this.objectToFormData(this.pl));
            var o_fd = this.formDataToObject(
                this.objectToFormData(this.pl_origin)
            );
            var f_fd = new FormData();
            var rows = '';
            for (var key in m_fd) {
                if (m_fd.hasOwnProperty(key)) {
                    var m_value =
                        m_fd[key] == 'true'
                            ? '1'
                            : m_fd[key] == 'false'
                            ? '0'
                            : m_fd[key];
                    var o_value =
                        o_fd[key] == 'true'
                            ? '1'
                            : o_fd[key] == 'false'
                            ? '0'
                            : o_fd[key];
                    var bool_values = [
                        ['inactive', 'active'],
                        ['disabled', 'enabled'],
                    ];
                    var bool_value =
                        key == 'status' ? bool_values[0] : bool_values[1];
                    if (!this.viewMode) {
                        // CREATE MODE
                        if (m_fd[key] != null) {
                            f_fd.append(key, m_value);
                        }
                    } else {
                        // EDIT MODE
                        if (
                            m_fd[key] != o_fd[key] &&
                            !['agent[commissions][enabled]'].includes(key)
                        ) {
                            f_fd.append(key, m_value);

                            rows += `<tr>
                                    <td>${this.arrayToArrows(key)}</td>
                                    <td class="${
                                        o_fd[key] == 'true'
                                            ? 'text-primary'
                                            : o_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            o_fd[key] == 'true'
                                                ? bool_value[1]
                                                : o_fd[key] == 'false'
                                                ? bool_value[0]
                                                : o_fd[key]
                                        }
                                    </td>
                                    <td class="${
                                        m_fd[key] == 'true'
                                            ? 'text-primary'
                                            : m_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            m_fd[key] == 'true'
                                                ? bool_value[1]
                                                : m_fd[key] == 'false'
                                                ? bool_value[0]
                                                : m_fd[key]
                                        }
                                    </td>
                                </tr>`;
                        }
                    }
                }
            }

            return {
                obj_array: this.formDataToObject(f_fd),
                form_data: f_fd,
                x_www_form_urlencoded: this.formDataToUrlEncoded(f_fd),
                changes: rows,
                original: o_fd,
                modified: m_fd,
            };
        },
    },
    methods: {
        ...mapActions('player', {
            playerGetList: 'getList',
        }),
        ...mapActions('agent', {
            agentGetList: 'getList',
        }),
        ...mapActions('coupon', {
            couponGetList: 'getList',
            couponCreate: 'create',
            couponDelete: 'delete',
        }),
        async initList(p) {
            this.resetScroll();
            var pl = {
                status: this.filters.status,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                page_no: p ? p : 1,
                search: this.filters.search ? this.filters.search : '',
                role: this.$route.params.role,
            };

            this.loading = true;
            const data = await this.couponGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
        },
        async submitForm() {
            if (!this.isAuthorized()) return;
            this.submitted = true;
            this.v$.pl.$touch();
            if (this.v$.pl.$invalid) {
                return;
            } else {
                var fd = new FormData();
                fd.append('username', this.pl.username);
                fd.append('amount', this.pl.amount);
                fd.append('code', this.pl.code);
                fd.append(
                    'expired_at',
                    this.dateYmD(this.pl.expired_at) + ' 23:59:59'
                );
                fd.append('note', this.pl.note);
                this.loading = true;
                const res = await this.couponCreate({ form_data: fd });
                this.loading = false;
                if (res) {
                    Swal.fire({
                        title: this.$t(res.data.message),
                        icon: res.success ? 'success' : 'error',
                        timer: 2000,
                    });
                    if (res.success) {
                        this.reset();
                        this.initList();
                    }
                }
            }
        },
        async remove(r) {
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: `${this.$t('delete this coupon')}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.couponDelete({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('coupon has been deleted')
                            : this.$t('deleting failed'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        resetFilter() {
            this.filters.search = '';
            this.filters.from = new Date(this.firstDateOftheMonth());
            this.filters.to = new Date();
        },
        reset() {
            this.submitted = false;
            this.create_modal = false;
            this.pl._id = null;
            this.pl.username = null;
            this.pl.amount = null;
            this.pl.code = null;
            this.pl.note = null;
            this.pl.expired_at = this.addDaysToDate(1);
            this.v$.pl.$reset();
        },
        generateCode() {
            this.pl.code = this.randomCouponCode();
        },
        async getUsernames() {
            this.v$.pl.username.$touch();
            this.user_options = [];
            this.pl.user_id = null;

            var pl = {
                limit: 100,
                username: this.pl.username || null,
            };
            const users =
                this.$route.params.role == 'player'
                    ? await this.playerGetList(pl)
                    : await this.agentGetList(pl);
            users.data.forEach((r) => {
                this.user_options.push({ name: r.username, value: r._id });
            });
            if (users.data.length === 1) {
                this.pl.username = users.data[0].username;
                this.pl.user_id = users.data[0]._id;
            }
        },
        focusUsername() {
            setTimeout(() => {
                this.$nextTick(() => this.$refs.username.focus());
            }, 500);
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
        },
    },
    mounted() {
        this.initList(1);
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('claim-coupon', () => {
                this.initList(1);
            });
        }
        this.$watch(
            () => this.$route.params,
            (n, o) => {
                if (this.$route.name == 'coupons') this.initList(1);
            }
        );
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('claim-coupon');
        }
    },
};
</script>
